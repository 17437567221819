import React from "react"
import { Alert, Button, Input } from "antd"
import { LoginInput } from "../../../../api/backend-api"
import { removeAuthToken, saveAuthToken } from "../../services/authService"
import { userLoginMutation, userQuery } from "../../../../api/user/api"

interface Props {
  redirectUrl: string
}

const loginUser = async (input: LoginInput) => {
  const loginResult = await userLoginMutation(input)

  if (
    !loginResult.data?.login.success ||
    !loginResult.data?.login.accessToken
  ) {
    return false
  }

  saveAuthToken(loginResult.data.login.accessToken)
  const user = await userQuery()
  if (!user.data?.getUser || !user.data.getUser.roles?.length) {
    removeAuthToken()
    return false
  }

  return true
}

const LoginForm = ({ redirectUrl }: Props) => {
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [loginError, setLoginError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleLogin = async () => {
    setLoginError(false)
    setLoading(false)

    if (
      await loginUser({
        email,
        password,
      })
    ) {
      setLoginError(false)
      setLoading(false)
      window.location.pathname = redirectUrl
    } else {
      setLoginError(true)
      setLoading(false)
    }
  }

  return (
    <div>
      <Input
        placeholder="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={(e) => (e.key === "Enter" ? handleLogin() : undefined)}
      />
      <Input
        placeholder="password"
        type="password"
        value={password}
        className="mt-2"
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => (e.key === "Enter" ? handleLogin() : undefined)}
      />
      <Button
        onClick={handleLogin}
        type="primary"
        className="w-full my-4"
        loading={loading}
      >
        Login
      </Button>
      {loginError && <Alert type="error" message="Credenziali non valide" />}
    </div>
  )
}

export default LoginForm
